import { HmacSHA256, enc } from 'crypto-js'
import * as process from 'process'

export const convertSignature = (data: string) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY

  if (!secretKey) {
    throw new Error('REACT_APP_SECRET_KEY is not defined')
  }

  return HmacSHA256(data, secretKey).toString(enc.Hex)
}
