import { useMutation, useQueryClient } from '@tanstack/react-query'
import { api, axiosConfig } from '../config'
import { ErrorWithResponse } from '../types'
import { toast } from 'react-toastify'

const getCheck = async () => {
  const config = axiosConfig()
  return await api.post('/operations/check', config.formdata, config.config)
    .then((res) => res.data)
}

export const useCheck = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(getCheck, {
    onSuccess: (res) => {
      queryClient.setQueryData(['data'], (oldData) => oldData ? {
        ...oldData,
        ...res,
      } : res)
    },
    onError: (err: ErrorWithResponse) => {
      toast.error(err.response.data && err.response.data.description)
    },
  })

  return mutation
}
