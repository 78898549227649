import React, { useState, useEffect} from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import PaymentCard from './PaymentCard'
import { useData } from 'api/operations/useData'
import Loader from 'components/Loader'
import { useCheck } from 'api/operations/useCheck'
import { useCancel } from 'api/operations/useCancel'
import Result from 'components/Result'
import ModalWindow from 'components/ModalWindow'
import Button from 'components/Button'
import Submitted from '../../components/Submitted'

const PaymentPage = () => {
  const { data, isLoading: isDataLoading, isError: isDataError, error } = useData()
  const { mutate: checkMutate } = useCheck()
  const { mutate: cancelMutate } = useCancel()

  const [returnModalActive, setReturnModalActive] = useState(false)

  const runCheck = () => {
    if (data && data.status && (data.status === 'VERIFYING_PAYMENT' || data.status === 'HOLD')) {
      checkMutate()
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const notification = urlParams.get('notification')
    const sms = urlParams.get('sms')
    const operation_id = urlParams.get('op')

    if (notification === 'true') {
      const hasTracked = sessionStorage.getItem('notificationTracked')

      if (!hasTracked) {
        amplitude.track('Go to the payment page by Notification')
        sessionStorage.setItem('notificationTracked', 'true')
      }
    }
    if (sms === 'true') {
      const hasTracked = sessionStorage.getItem('smsTracked')

      if (!hasTracked) {
        amplitude.track('Go to the payment page by SMS', {
          operation_id: operation_id
        })
        sessionStorage.setItem('smsTracked', 'true')
      }
    }
  }, [])

  useEffect(() => {
    if (!isDataLoading && !isDataError && data.operation_id) {
      const checkInterval = setInterval(() => runCheck(), 5000)
      return () => clearInterval(checkInterval)
    }
  }, [isDataLoading, isDataError, data])

  if (isDataLoading) return <Loader />
  if (isDataError) return <Result error={error?.response} data={error?.response?.data} />

  if (data && data?.status === 'VERIFYING_PAYMENT' || (data && data?.status === 'HOLD' && !data?.message)) return <Submitted data={data} />
  if (data && data?.status === 'SUCCESS') return <Result success data={data} />
  if (data && data?.status === 'FAILED') return <Result data={data} />
  if (data && data?.status === 'HOLD' && data?.message) return <Result data={data} />

  const handleCancel = () => {
    cancelMutate()
  }

  return (
    data.payment_credentials ?
      <div className={'flex flex-col w-full gap-0 sm:gap-3 sm:w-[470px] pt-2 sm:pt-0'}>
        <div className={'flex flex-col gap-0 sm:gap-3'}>
          <PaymentCard/>

          <ModalWindow active={returnModalActive} setActive={setReturnModalActive}>
            <div className='grid gap-[20px] grid-cols-1 w-full close_and_return_modal gap-20'>
              <p className='text-center'>
                Are you sure you want to cancel and return to the merchant?
              </p>
              <div className='grid gap-[8px] grid-cols-1 w-full'>
                <Button
                  title={'Return to Merchant'}
                  className={'btn-sm h-10 w-full bg-red text-white btn-danger sm:flex hover:border-red hover:bg-white hover:text-red'}
                  onClick={handleCancel}
                />
                <Button
                  title={'Go Back'}
                  className={'btn-sm h-10 w-full outline-[#A9A9A9] text-[#A9A9A9] btn-outline sm:flex'}
                  onClick={() => setReturnModalActive(false)}
                />
              </div>
            </div>
          </ModalWindow>
        </div>
      </div>
      : <div>hello</div>
  )
}

export default PaymentPage
