import React, { useEffect} from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import Button from '../Button'
import Text from '../Text'
import { IOperationData } from '../../api/types'
import { AxiosError } from 'axios'
import { handleLocation } from '../../utils/handleLocation'
import Card from '../Card'
import { Icons } from '../../assets/images'
import ModalScreenshotImg from 'components/ModalScreenshotImg'
import OpenTidio from '../Tidio/openTidio'

interface IResult {
  error?: AxiosError
  data: IOperationData
  success?: boolean
}

interface ISmallError {
  text?: string
  handleGoBackClick: () => void
}

const SmallError = ({ text }: ISmallError) => {
  return (
    <Card className={'flex flex-col items-center gap-5'}>
      <Text className={'text-error'}>
        Error: {text}
      </Text>
      {/*<Button*/}
      {/*  title={'Go back'}*/}
      {/*  onClick={handleGoBackClick}*/}
      {/*  className={'w-[145px]'}*/}
      {/*/>*/}
    </Card>
  )
}

const Result = ({ error, data, success }: IResult) => {
  // const [supportEnabled, setSupportEnabled] = useState<boolean>(true)

  const handleGoBackClick = () => {
    if (data.redirect_url) handleLocation(data.redirect_url, 'Click Go Back Error Page')
  }

  if (error?.status === 422 && data) {
    return <SmallError text={data.description} handleGoBackClick={handleGoBackClick} />
  }

  const attachId = () => {
    const url =  data.image_url || ' '
    const startIndex = url.lastIndexOf('/') + 1
    const endIndex = url.lastIndexOf('.')
    const result = url.substring(startIndex, endIndex)
    return result
  }

  useEffect(() => {
    if (success) {
      const urlParams = new URLSearchParams(window.location.search)
      const notification = urlParams.get('notification')
      const sms = urlParams.get('sms')
      const operation_id = urlParams.get('op')

      if (notification === 'true') {
        const hasTracked = sessionStorage.getItem('successNotificationPaymentTracked')

        if (!hasTracked) {
          amplitude.track('Success payment by Notification')
          sessionStorage.setItem('successNotificationPaymentTracked', 'true')
        }
      }
      if (sms === 'true') {
        const hasTracked = sessionStorage.getItem('successSmsPaymentTracked')

        if (!hasTracked) {
          amplitude.track('Success payment by SMS', {
            operation_id: operation_id
          })
          sessionStorage.setItem('successSmsPaymentTracked', 'true')
        }
      }
    }
    // OpenTidio()
  }, [])

  return (
    data ?
      <Card className={'flex flex-col w-[470px] justify-center items-center'}>
        {success ? <Icons.SuccessIcon /> : <Icons.ErrorIcon />}

        <Text
          className={'text-xl font-medium mt-5 mb-[10px]'}
        >
          {success ? 'Deposit Successful' : 'Deposit Failed'}
        </Text>

        {success ? <div className={'flex justify-center w-[470px] text-center flex-col gap-5 mb-5'}>
          <Text className={'flex justify-center text-gray font-roboto text-sm font-normal tracking-normal mb-5'}>
            Thank you! Your payment is complete
          </Text>
          <Button
            title={'Back to Casino'}
            className={'btn-success  whitespace-nowrap text-white w-[145px] self-center'}
            onClick={handleGoBackClick}
          />
        </div> :
          <div className={'flex justify-center text-center w-full flex-col gap-5 mb-5'}>
            <div className={'flex w-full justify-between'}>
              <Text className={'text-opacity-50'}>
              Amount:
              </Text>
              <Text>
                {data.amount}
              </Text>
            </div>
            <div className={'flex w-full justify-between'}>
              <Text className={'text-opacity-50'}>
              Transaction ID:
              </Text>
              <Text>
                {data.transaction_id}
              </Text>
            </div>
            {data.message && <div className={'flex w-full justify-between'}>
              <Text className={'text-opacity-50'}>
              Description:
              </Text>
              <Text>
                {data.message}
              </Text>
            </div>}
            {window.location.pathname.includes('full') && <div className={'flex w-full justify-between'}>
              <Text className={'text-opacity-50'}>
              Attachment:
              </Text>
              <a className={'text-right text-blue underline hover:text-blue-800'} href={data.image_url}>
                {attachId()}
              </a>
            </div>}
            {window.location.pathname.includes('full') && <div className={'flex w-full justify-between'}>
              <Text className={'text-opacity-50'}>
              UTR:
              </Text>
              <Text>
                {data.utr}
              </Text>
            </div>}
            {window.location.pathname.includes('full') && data.image_url && 
            <div>
              <ModalScreenshotImg imageUrl={data.image_url} />
            </div>}
          </div>}
        {!success && <Button
          title={'Talk to Support'}
          className={'btn-success flex justify-center whitespace-nowrap text-white chat-button w-[145px]'}
          onClick={ () => {
            // setSupportEnabled(false)
            amplitude.track('Click Talk to Support')
            OpenTidio()
          }}
          // disabled={!supportEnabled}
        />}
      </Card>
      :
      <SmallError text={'Unknown error'} handleGoBackClick={handleGoBackClick} />
  )
}

export default Result
