import React, { InputHTMLAttributes, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Controller, useFormContext } from 'react-hook-form'
import { validateTextFieldValue } from '../../utils/validateTextFieldValue'

interface ITextInput extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  name: string
  placeholder: string
  className?: string
  rules?: object
}

const TextInput = ({
  className,
  id,
  name,
  placeholder,
  rules,
  ...props
}: ITextInput) => {

  const {
    control,
  } = useFormContext()

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={`relative ${!value && !isFocused && ''}`}>
            <input
              id={id}
              type='text'
              onChange={(e) =>
                onChange(validateTextFieldValue(e.target.value))
              }
              value={value}
              placeholder={placeholder}
              className={
                twMerge(
                  'input input-accent text-sm w-full rounded-xl focus:outline-none bg-accent text-white placeholder:text-[rgba(35,35,35,0.5)] placeholder:text-opacity-60 duration-150 sm:focus:outline-offset-0 sm:focus:outline-4',
                  className,
                )}
              onBlur={handleBlur}
              onFocus={handleFocus}
              {...props}
            />
          </div>
        )
      }}
    />
  )
}

export default TextInput
