import * as amplitude from '@amplitude/analytics-browser'

export const downloadQR = () => {
  amplitude.track('Click Download QR')

  const freeSpaceSize = 50

  const temp = document.createElement('img')

  const svgElement = document.getElementById('qrcode')
  if (svgElement) {
    const svgData = svgElement.outerHTML
    const svgBlob: Blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
    temp.src = URL.createObjectURL(svgBlob)
    temp.setAttribute('style', 'position:fixed;left:-200vw;')
    document.body.appendChild(temp)
  } else {
    console.error('Element with id \'qrcode\' not found.')
  }

  temp.onload = function onload() {
    const canvas = document.createElement('canvas')
    canvas.width = temp.clientWidth + freeSpaceSize
    canvas.height = temp.clientHeight + freeSpaceSize
    const ctx = canvas.getContext('2d')
    if (!ctx) return
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(temp, freeSpaceSize / 2, freeSpaceSize / 2)

    const jpegUrl = canvas.toDataURL('image/jpeg')
    const downloadLink = document.createElement('a')
    const newDate = new Date().toLocaleString().replace(', ', '_')
    downloadLink.href = jpegUrl
    downloadLink.download = `depopay_${newDate}.jpeg`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    document.body.removeChild(temp)
  }
}
