import React from 'react'
import { twMerge } from 'tailwind-merge'

interface IText {
  children: React.ReactNode
  className?: string
}

const Text = ({ children, className }: IText) => {
  return (
    <p className={twMerge('text-base font-normal text-secondary', className)}>
      {children}
    </p>
  )
}

export default Text
