import React from 'react'
import { twMerge } from 'tailwind-merge'

interface ICard {
  children: React.ReactNode;
  className?: string
}

const Card = ({ children, className }: ICard) => {
  return (
    <div className={twMerge('card bg-white p-4 rounded-xl', className)}>
      {children}
    </div>
  )
}

export default Card
