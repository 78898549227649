import React, { useState, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import Text from 'components/Text'
import Button from 'components/Button'
import { useData } from '../../../../api/operations/useData'
import { toast } from 'react-toastify'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'


import { useSendImg } from '../../../../api/operations/useSendImg'
import { useChangeVPA } from '../../../../api/operations/useChangeVPA'
import { useSendUTR, ISendUTRProps } from '../../../../api/operations/useSendUTR'
import TextInput from 'components/TextInput'
import { Icons } from '../../../../assets/images'
import Timer from '../../../../components/Timer'
import CoverLoader from 'components/CoverLoader'

const CopyUpiPart = () => {
  const { data } = useData()
  const [waiting, setWaiting] = useState<boolean>(false)
  const [test, setTest] = useState<boolean>(false)
  const [active, isActive] = useState<boolean>(true)
  const [onFocus, setOnFocus] = useState<boolean>(false)
  const [disableUtrSend, setDisableUtrSend] = useState<boolean>(true)

  const { mutate: sendImgMutate } = useSendImg()
  const { mutate: sendUTRMutate } = useSendUTR()
  const { mutate: changeVPAMutate } = useChangeVPA()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const test = urlParams.get('test') ?? 'false'
    setTest(test === 'true')
  }, [])

  const handleSendImage = (imageData: File, setWaiting: any) => {
    setWaiting(true)

    sendImgMutate({ image: imageData }, {
      onSuccess: () => {
        setWaiting(false)
      },
      onError: (error: Error) => {
        console.error('Error:', error)
        setWaiting(false)
      }
    })
  }

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { utr: '' },
  })

  const {
    handleSubmit
  } = formMethods

  const onSubmit: SubmitHandler<ISendUTRProps> = (data: ISendUTRProps) => {
    setWaiting(true)
    amplitude.track('Click Submit UTR')
    sendUTRMutate(data, {
      onSuccess: () => {
        setWaiting(false)
      },
      onError: (error: Error) => {
        console.error('Error:', error)
        setWaiting(false)
      }
    })
  }

  const changePaymentAddress = () => {
    setWaiting(true)
    amplitude.track('Click Change UPI Address')
    changeVPAMutate(undefined, {
      onSettled: () => {
        setWaiting(false)
      },
    })
  }

  const copyUpi = () => {
    if (data && data.payment_credentials) {
      amplitude.track('Click Copy UPI')
      const tempInput = document.createElement('input')
      tempInput.value = data.payment_credentials
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let inputValue = event.target.value

    inputValue = inputValue.replace(/[^0-9]/g, '')

    if (inputValue.length > 12) {
      inputValue = inputValue.slice(0, 12)
    }

    event.target.value = inputValue
    if (inputValue.length > 0){
      setOnFocus(true)}
    else {
      setOnFocus(false)
    }

    if (inputValue.length == 12) {
      setDisableUtrSend(false)
    } else {
      setDisableUtrSend(true)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text/plain')
    if (!/^[0-9]*$/.test(pasteData)) {
      event.preventDefault()
    }
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file: File = event.target.files[0]

    amplitude.track('Click Upload Screenshot')

    if (file && file.size > 10 * 1024 * 1024) { // 10 MB in bytes
      toast.error('Screenshot is too large. Max size - 10 MB')
      event.target.value = ''
      return
    }

    handleSendImage(file, setWaiting)

    // const reader = new FileReader()

    // reader.onload = () => {
    //   const base64String = reader.result?.toString()
    //   // Вот где мы используем handleSendImage
    //   handleSendImage(base64String || '', setWaiting)
    // }

    // try {
    //   reader.readAsDataURL(file)
    // } catch (error) {
    //   setWaiting(false)
    // }
  }
  return (
    <div>
      {waiting && <CoverLoader/>}
      <div onClick={()=> isActive(false)} className={'flex flex-col justify-center items-center gap-20 w-339px p-3 px-0 rounded-st'}>
        <div className={'flex flex-col gap-3 top-0 right-4 absolute pt-2'}>
          {data?.payment_credentials ?
            <Timer data={data}/>
            : null
          }
        </div>
        <div className="flex h-[23px] p-[5px_16px] justify-center items-center gap-[2px] rounded-[80px] border border-gray-200 bg-white absolute sm:top-[48px] top-[58px] left-[23px] text-sm"><p className={'text-blue font-bold'}>Step 1</p>• Copy UPI address</div>
        <div className={`flex flex-col justify-center items-center gap-[10px] p-3 px-3 self-stretch rounded-[12px] border border-grayLight mb-[40px] ${test ? 'h-[150px]' : 'h-[70px]'} mt-[36px]`}>
          <div className={'flex justify-end items-center gap-[10px] self-stretch'}>
            <div className={'truncate text-gray-600/50 text-center font-roboto text-base font-normal leading-relaxed opacity-50 w-[216px]'}>
              {data && data.payment_credentials}
            </div>
            <Button
              title={'Copy UPI'}
              className={'btn-sm rounded-[10px] text-sm pr-16 pl-16 bg-blue text-white h-10 btn-outline w-[100px] w-[100px] whitespace-nowrap border-0'}
              onClick={copyUpi}
            />
          </div>
          {active && <Icons.CloudIcon className="absolute top-[72px] z-10" onClick={()=> isActive(false)}/>}
          {test &&
          <div className={'flex flex-col justify-center column items-center py-[5px] gap-10 mt-[8px] self-stretch border border-blue border-dashed rounded-[12px]'}>
            <div className={'flex justify-center items-center w-full gap-3 rounded-lt'}>
              <div className={'flex flex-col justify-center items-center p-[12px]'}>
                <Text className={'text-center font-roboto text-base font-bold leading-relaxed text-blue'}>
                  Payment issues?
                </Text>
              </div>
              <div className={'flex justify-between items-center self-stretch rounded-[10px] bg-[#F8F8FA] border-0'}>
                <Button
                  title={'Change UPI Address'}
                  className={'btn-sm rounded-[10px] text-sm pr-16 pl-16 text-white h-8 btn-outline w-[160px] mr-[4px] whitespace-nowrap border-0 bg-red'}
                  onClick={changePaymentAddress}
                  disabled={waiting}
                />
              </div>
            </div>
          </div>
          }
        </div>
        <div
          className={`flex h-[23px] p-[5px_16px] justify-center items-center gap-[2px] rounded-[80px] border border-gray-200 bg-white absolute top-[166px] ${test ? 'sm:top-[238px]' : 'sm:top-[158px]'} left-[23px] text-sm`}><p className={'text-blue font-bold'}>Step 2</p>• Make payment</div>
        <div
          className={'flex flex-col justify-center items-center gap-10 p-[15px] px-8 self-stretch rounded-[12px] border border-grayLight mb-[40px] h-[72px] '}>
          <div className={'flex flex-col justify-center items-center gap-[5px] self-stretch'}>
            <div
              className={'flex text-[rgba(35,35,35,0.5)] text-center font-roboto text-base font-normal leading-relaxed'}>
              Open your UPI app, paste UPI address and </div>
            <div className={'flex text-blue font-bold opacity-100 text-center font-roboto text-base '}>&nbsp;pay {data && data.amount} INR&nbsp;</div>
          </div>
        </div>
        <div
          className={`flex h-[23px] p-[5px_16px] justify-center items-center gap-[2px] rounded-[80px] border border-gray-200 bg-white absolute top-[278px] ${test ? 'sm:top-[348px]' : 'sm:top-[268px]'} left-[23px] text-sm`}><p className={'text-blue font-bold'}>Step 3</p>•
          Confirm payment
        </div>
        <div
          className={'flex flex-col justify-center items-center gap-[10px] p-5 px-3 self-stretch rounded-[12px] border border-grayLight'}>
          <div
            className={'flex flex-col justify-center column items-center gap-10 py-20 self-stretch border border-blue border-dashed rounded-[12px]'}>
            <label htmlFor="paymentScreenshot"
              className={`flex justify-center items-center w-full gap-3 rounded-lt ${waiting ? 'opacity-50' : 'cursor-pointer'}`}>
              <div className={'flex flex-col justify-center items-center p-[20px] px-[12px]'}>
                <Icons.ImageIconBlue/>
                <Text className={'text-center font-roboto text-base font-bold leading-relaxed text-blue'}>
                  Upload payment screenshot with UTR
                </Text>
                <Text className={'text-[rgba(35,35,35,0.5)] font-roboto text-center text-sm font-normal leading-relaxed'}>
                  Take screenshot of payment confirmation with UTR number
                </Text>
              </div>
              <input
                id="paymentScreenshot"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleFileInputChange}
                disabled={waiting}
              />
            </label>
          </div>
          <div className={'opacity-50 text-sl font-bold'}>OR</div>
          <div className={'flex flex-col justify-center column items-center gap-10 py-20 self-stretch border border-blue border-dashed rounded-[12px]'}>
            <label className={`flex justify-center items-center w-full gap-3 rounded-lt ${waiting ? 'opacity-50' : 'cursor-pointer'}`}>
              <div className={'flex flex-col justify-center items-center p-[20px] px-[12px]'}>
                <Text className={'text-center font-roboto text-base font-bold leading-relaxed text-blue mb-[6px]'}>
                  Enter UTR number
                </Text>
                <Text className={'text-[rgba(35,35,35,0.5)] font-roboto text-sm font-normal leading-relaxed mb-2'}>
                  Paste UTR number from UPI app
                </Text>
                <div className={`flex justify-between items-center self-stretch rounded-[10px] bg-[#F8F8FA] border-0 ${onFocus && 'border-borderColor border-2'}`}>
                  <FormProvider {...formMethods}>
                    <TextInput
                      className={'flex justify-center text-sm placeholder-lightGray text-[rgba(35,35,35,0.5)] items-center gap-10 px-4 py-4 self-stretch border-1 rounded-lg bg-[#F8F8FA] border-0'}
                      id={'utrNumber'}
                      name={'utr'}
                      placeholder={'Enter 12 digit UTR number'}
                      rules={{
                        required: true,
                        pattern: /^[0-9]+$/i,
                        maxLength: 12,
                        minLength: 12,
                      }}
                      pattern="[0-9]*"
                      type="tel"
                      onInput={handleInputChange}
                      onFocus={()=>setOnFocus(true)}
                      onPaste={handlePaste}
                    />
                    <Button
                      title={'Submit UTR'}
                      className={`btn-sm rounded-[10px] text-sm pr-16 pl-16 text-white h-10 btn-outline w-[100px] mr-[4px] whitespace-nowrap border-0 ${disableUtrSend ? 'bg-btnGray' : 'bg-blue'}`}
                      onClick={handleSubmit(onSubmit)}
                      disabled={disableUtrSend}
                    />
                  </FormProvider>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CopyUpiPart
