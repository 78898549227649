import React from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { useEffect } from 'react'
import Text from '../Text'
import { IOperationData } from '../../api/types'
import { AxiosError } from 'axios'
import { handleLocation } from '../../utils/handleLocation'
import Card from '../Card'
import { Icons } from '../../assets/images'
import Timer from '../Timer'
import ModalScreenshotImg from 'components/ModalScreenshotImg'

interface IResult {
  error?: AxiosError
  data: IOperationData
  success?: boolean
}

interface ISmallError {
  text?: string
  handleGoBackClick: () => void
}

const SmallError = ({ text }: ISmallError) => {
  return (
    <Card className={'flex flex-col items-center gap-5'}>
      <Text className={'text-error'}>
        Error: {text}
      </Text>
      {/*<Button*/}
      {/*  title={'Go back'}*/}
      {/*  onClick={handleGoBackClick}*/}
      {/*  className={'w-[145px]'}*/}
      {/*/>*/}
    </Card>
  )
}

const Submitted = ({ error, data }: IResult) => {

  const handleGoBackClick = () => {
    if (data.url) handleLocation(data.url, 'Click Go Back Error Page')
  }

  if (error?.status === 422 && data) {
    return <SmallError text={data.description} handleGoBackClick={handleGoBackClick} />
  }

  useEffect(() => {
    const verifyingPaymentTracked = sessionStorage.getItem('verifyingPaymentTracked')
    const holdOperationTracked = sessionStorage.getItem('holdOperationTracked')

    if (data.status === 'VERIFYING_PAYMENT' && !verifyingPaymentTracked) {
      sessionStorage.setItem('verifyingPaymentTracked', 'true')
      amplitude.track('Start Verifying Payment')
    } else if (data.status === 'HOLD' && !holdOperationTracked) {
      sessionStorage.setItem('holdOperationTracked', 'true')
      amplitude.track('Hold Operation')
    }
  }, [data.status])


  const attachId = () => {
    const url =  data.image_url || ' '
    const startIndex = url.lastIndexOf('/') + 1
    const endIndex = url.lastIndexOf('.')
    const result = url.substring(startIndex, endIndex)
    return result
  }

  return (
    data ?
      <Card className={'flex flex-col sm:w-[470px] justify-center items-center'}>
        <Icons.SubmittedIcon/>
        <Text
          className={'text-xl font-medium mt-5'}
        >
         Deposit Submitted
        </Text>
        <Text className={'text-gray font-roboto text-sm font-normal tracking-normal mb-5 text-center'}>
          It can take up to 3 minutes to process
        </Text>
        <div className={'flex w-full flex-col gap-5 mb-5'}>
          <div className={'flex w-full justify-between'}>
            <Text className={'text-opacity-50'}>
              Amount:
            </Text>
            <Text>
              {data.amount}
            </Text>
          </div>
          <div className={'flex w-full justify-between'}>
            <Text className={'text-opacity-50'}>
              Transaction ID:
            </Text>
            <Text>
              {data.transaction_id}
            </Text>
          </div>
          {data.message && <div className={'flex w-full justify-between'}>
            <Text className={'text-opacity-50'}>
            Description:
            </Text>
            <Text>
              {data.message}
            </Text>
          </div>}
          {window.location.pathname.includes('full') && <div className={'flex w-full justify-between'}>
            <Text className={'text-opacity-50'}>
              Attachment:
            </Text>
            <a className={'text-right text-blue underline hover:text-blue-800'} href={data.image_url}>
              {attachId()}
            </a>
          </div>}
          {window.location.pathname.includes('full') && <div className={'flex w-full justify-between'}>
            <Text className={'text-opacity-50'}>
              UTR:
            </Text>
            <Text>
              {data.utr}
            </Text>
          </div>}
          {window.location.pathname.includes('full') && data.image_url &&
          <div>
            <ModalScreenshotImg imageUrl={data.image_url} />
          </div>}
        </div>
        <div className={'rounded-lt flex text-center items-center justify-center bg-bgGray font-normal text-base text-secondary h-12 p-5 w-[145px]'} onClick={handleGoBackClick}>
          {<Timer data={data}/>}
        </div>
        {data && data.status && data.status === 'VERIFYING_PAYMENT' && <div className={'bg-[#00CB0B1A] gap-1.75 mt-5 justify-center items-center rounded-lt flex h-14 pt-3 pb-3 pl-2.5 pr-2.5 w-[300px]'}>
          <Icons.AlertIcon/>
          <Text className={'text-sm pl-2 font-normal text-[#232323] w-[247px]'}>
            Please don’t close this page while processing
          </Text>
        </div>}
      </Card>
      :
      <SmallError text={'Unknown error'} handleGoBackClick={handleGoBackClick} />
  )
}

export default Submitted
