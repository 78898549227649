import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'

interface IQRCodeComponent {
  value: string
  oneSize?: number | false
}

const QRCodeComponent = ({ value, oneSize }: IQRCodeComponent) => {
  const [size, setSize] = useState(140)

  const updateSize = () => {
    if (window.innerWidth < 640) {
      setSize(160)
    } else {
      setSize(140)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return (
    <QRCode
      id='qrcode'
      size={oneSize || size}
      level={'H'}
      value={value}
    />
  )
}

export default QRCodeComponent
