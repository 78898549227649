import React from 'react'

const CoverLoader = () => {
  return (
    <div className={'fixed top-0 left-0 w-full h-full bg-gray/60 flex flex-col justify-center items-center z-50'}>
      <div className={'flex flex-col justify-center items-center gap-2'}>
        <span className='loading loading-spinner text-orange loading-lg mb-2' />
      </div>
    </div>
  )
}

export default CoverLoader
