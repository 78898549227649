import React from 'react'
import { twMerge } from 'tailwind-merge'
import 'index.css'
interface IButton {
  title: string
  className?: string
  onClick: (args: React.MouseEvent<Element, MouseEvent>) => void
  disabled?: boolean
}

const Button = ({
  title,
  className,
  onClick,
  disabled,
}: IButton) => {
  return (
    <button
      className={twMerge(`btn normal-case text-lg ${disabled ? '!bg-btnGray !text-white' : '!bg-blue'} `, className)}
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      {title}
    </button>
  )
}

export default Button
