import { useMutation, useQueryClient } from '@tanstack/react-query'
import { api, axiosConfig } from '../config'
import { ErrorWithResponse } from '../types'
import { toast } from 'react-toastify'

const changeVPA = async () => {
  const config = axiosConfig()
  return await api.post('/operations/update-payment-credentials', config.formdata, config.config)
    .then((res) => res.data)
}

export const useChangeVPA = () => {
  const queryClient = useQueryClient()

  return useMutation(changeVPA, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data'])
    },
    onError: (err: ErrorWithResponse) => {
      toast.error(err.response.data && err.response.data.description)
    },
  })
}
