import React, { useState } from 'react'
import Text from 'components/Text'
import CoverLoader from 'components/CoverLoader'
import Button from 'components/Button'
import QRCode from 'components/QRCode'
import { downloadQR } from '../../../../utils/downloadQR'
import { useData } from '../../../../api/operations/useData'


import { useMadePayment } from '../../../../api/operations/useMadePayment'
import Timer from '../../../../components/Timer'


const QRCodePart = () => {
  const { data } = useData()
  const [waiting, setWaiting] = useState<boolean>(false)

  const { mutate } = useMadePayment()

  const handleMadePayment = () => {
    setWaiting(true)
  
    mutate(undefined, {
      onSuccess: () => {
        setWaiting(false)
      },
      onError: (error: Error) => {
        console.error('Error:', error)
        setWaiting(false)
      }
    })
  }

  return (
    <div>
      {waiting && <CoverLoader/>}
      <div className={'flex flex-col justify-center items-center gap-20 w-339px p-3 rounded-st bg-bgGray'}>
        <div className={'flex flex-col gap-3 items-center sm:items-start'}>
          {data?.payment_credentials ?
            <Timer data={data}/>
            : null
          }

          <Text className={'text-green text-center font-roboto text-xl font-extrabold leading-normal'}>
          Pay: {`₹${data?.amount?.toFixed(0)}`}
          </Text>
        </div>

        <div className={'relative flex flex-col justify-center items-center gap-3'}>
          <QRCode
            value={data?.payment_link || ''}
          />

          <Button
            title={'Download QR'}
            className={'btn-sm text-sm pr-16 pl-16 bg-secondary text-white h-10 btn-outline w-[100px] w-[100px] whitespace-nowrap'}
            onClick={downloadQR}
          />
        </div>
      </div>
      <div className={'flex flex-col w-300 items-start gap-19 mt-8.5 mb-8.5 pl-6 pr-6'}>
        <div className={'text-gray font-roboto text-sm font-normal tracking-normal uppercase mb-5'}>
      Steps to complete the payment
        </div>
        <div className={'flex items-start gap-2 self-stretch mb-5'}>
          <div className={'flex flex-col text-sm justify-center items-center w-5 h-5 gap-2.5 rounded-full bg-gray200'}>1</div>
      Download QR
        </div>
        <div className={'flex items-start gap-2 self-stretch mb-5'}>
          <div
            className={'flex flex-col text-sm justify-center items-center w-5 h-5 gap-2.5 rounded-full bg-gray200'}>2
          </div>
          Make payment
        </div>
        <div className={'flex items-start gap-2 self-stretch'}>
          <div
            className={'flex flex-col text-sm justify-center items-center w-5 h-5 gap-2.5 rounded-full bg-gray200'}>3
          </div>
          Click ’I Made a Payment’
        </div>
      </div>
      <form>
        <label htmlFor="madePayment"
          className={`flex justify-center items-center w-full h-12 gap-3 rounded-lt bg-blue ${waiting ? 'opacity-50' : 'cursor-pointer'}`}>
          <Text className={'text-lg font-semibold text-white'}>
            I Made a Payment
          </Text>
          <input
            id="madePayment"
            className="hidden"
            onClick={handleMadePayment}
            disabled={waiting}
          />
        </label>
      </form>
    </div>
  )
}

export default QRCodePart
