import { useMutation } from '@tanstack/react-query'
import { api, axiosConfig } from '../config'
import { ErrorWithResponse } from '../types'
import { toast } from 'react-toastify'
import { handleLocation } from '../../utils/handleLocation'

const getCancelUrl = async () => {
  const config = axiosConfig()
  return await api.post('/operations/cancel', config.formdata, config.config)
    .then((res) => res.data)
}

export const useCancel = () => {
  const mutation = useMutation(getCancelUrl, {
    onSuccess: (res) => {
      handleLocation(res.url, 'Click Cancel')
    },
    onError: (err: ErrorWithResponse) => {
      toast.error(err.response.data && err.response.data.description)
    },
  })

  return mutation
}
