import React from 'react'
import Card from 'components/Card'
import QRCodePart from './QRCodePart'
import { useData } from '../../../api/operations/useData'
import CopyUpiPart from './CopyUpiPart'

const PaymentCard = () => {
  const { data } = useData()

  return (
    <div className={'relative'}>
      <Card className={'relative sm:p-3 pb-3 sm:pb-4 pt-5'}>
        {/* <CopyUpiPart/> */}
        {data && !data.is_invoice ? <CopyUpiPart/> : <QRCodePart/>}
      </Card>
    </div>
  )
}

export default PaymentCard
