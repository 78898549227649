import React from 'react'
import Text from '../Text'

interface ILoader {
  waitText?: boolean
}

const Loader = ({ waitText }: ILoader) => {
  return (
    <div className={'flex flex-col justify-center items-center gap-2'}>
      <span className='loading loading-spinner text-orange loading-lg mb-2' />
      {waitText ?
        <>
          <Text className={'text-xl font-medium'}>
            Please wait
          </Text>
          <Text className={'text-opacity-50'}>
            Payment in progress.
          </Text>
        </>
        : null}
    </div>
  )
}

export default Loader
