import React, { Dispatch, SetStateAction } from 'react'
import './styles.css'


interface IModalWindow {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
  children: JSX.Element
}

const ModalWindow = ({ active, setActive, children }: IModalWindow) => {
  return (
    <div className={active ? 'modal_window active' : 'modal_window'} onClick={() => setActive(false)}>
      <div className={active ? 'modal_window_content active' : 'modal_window_content'} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default ModalWindow