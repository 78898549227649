import React from 'react'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import PaymentPage from 'pages/PaymentPage'
import Layout from 'components/Layout'
import { ToastContainer } from 'react-toastify'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      retryDelay: 1000,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <PaymentPage />
      </Layout>
      <ToastContainer />
    </QueryClientProvider>
  )
}

export default App
