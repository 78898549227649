import { useMutation, useQueryClient } from '@tanstack/react-query'
import { api, axiosConfig } from '../config'
import { ErrorWithResponse } from '../types'
import { toast } from 'react-toastify'

export interface ISendImgProps {
  image: File
}

const sendImg = async (data: ISendImgProps) => {
  const config = axiosConfig(data)
  return await api.post('/operations/screenshot', config.formdata, config.config)
    .then((res) => res.data)
}

export const useSendImg = () => {
  const queryClient = useQueryClient()

  return useMutation(sendImg, {
    onSuccess: () => {
      queryClient.invalidateQueries(['data'])
    },
    onError: (err: ErrorWithResponse) => {
      toast.error(err.response.data && err.response.data.description)
    },
  })
}
