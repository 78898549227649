import React, { useState } from 'react'
import Text from '../Text'

interface ModalScreenshotImgProps {
  imageUrl: string | undefined
}

const ModalScreenshotImg: React.FC<ModalScreenshotImgProps> = ({ imageUrl}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleImageError = () => {
    setError(true)
  }

  return (
    <div>
      <div className={'flex w-full justify-center'}>
        {error ? (
          <Text className={'text-opacity-50'}>
            Unable to load screenshot
          </Text>
        ) : (
          <img
            onClick={openModal}
            src={imageUrl}
            alt="Screenshot"
            style={{ width: '35%', cursor: 'pointer' }}
            onError={handleImageError}
          />
        )}
      </div>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          <img
            src={imageUrl}
            alt="Screenshot"
            style={{ maxWidth: '90%', maxHeight: '90%' }}
            onError={handleImageError}
          />
        </div>
      )}
    </div>
  )
}

export default ModalScreenshotImg
