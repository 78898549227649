import { convertSignature } from '../utils/convertSignature'
import * as process from 'process'
import axios from 'axios'

export interface DataProps {
  image?: File,
  utr?: string
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

export const axiosConfig = (data: DataProps = {}) => {
  const params = new URLSearchParams(document.location.search)
  const op = params.get('op') ?? '' // is the string "Jonathan"

  const image = data.image
  const utr = data.utr

  const formdata = new FormData()
  formdata.append('op', op)
  if (image) {
    formdata.append('image', image, image.name)
  }
  if (utr) {
    formdata.append('utr', utr)
  }

  const hash_body = {
    op: op,
    ...(utr ? { utr } : {})
  }
  const raw = JSON.stringify(hash_body)
  const data_hash = convertSignature(raw)
  const HMAC_SIGNATURE_HEADER = process.env.REACT_APP_HMAC_SIGNATURE_HEADER

  return {
    config: {
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
        'Content-Type': 'multipart/form-data',
        [HMAC_SIGNATURE_HEADER!]: data_hash,
      },
    },
    formdata,
  }
}
