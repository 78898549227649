import { ReactComponent as PhonePe } from 'assets/images/PhonePeIcon.svg'
import { ReactComponent as GPay } from 'assets/images/GPayIcon.svg'
import { ReactComponent as Amazon } from 'assets/images/AmazonIcon.svg'
import { ReactComponent as PayTm } from 'assets/images/PayTmIcon.svg'
import { ReactComponent as PhonePeExample } from 'assets/images/PhonePeExample.svg'
import { ReactComponent as GPayExample } from 'assets/images/GPayExample.svg'
import { ReactComponent as AmazonExample } from 'assets/images/AmazonExample.svg'
import { ReactComponent as PayTmExample } from 'assets/images/PayTmExample.svg'
import { ReactComponent as Question } from 'assets/images/QuestionIcon.svg'
import { ReactComponent as ErrorIcon } from 'assets/images/ErrorIcon.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/SuccessIcon.svg'
import { ReactComponent as SubmittedIcon } from 'assets/images/SubmittedIcon.svg'
import { ReactComponent as AlertIcon } from 'assets/images/AlertIcon.svg'
import { ReactComponent as ImageIcon } from 'assets/images/ImageIcon.svg'
import { ReactComponent as ImageIconBlue } from 'assets/images/ImageIconBlue.svg'
import { ReactComponent as CloudIcon } from 'assets/images/Cloud.svg'
import  HowToPayMan from 'assets/images/HowToPayMan.svg'

export const Icons = {
  PhonePe,
  GPay,
  Amazon,
  PayTm,
  PhonePeExample,
  GPayExample,
  AmazonExample,
  PayTmExample,
  Question,
  ErrorIcon,
  SuccessIcon,
  SubmittedIcon,
  AlertIcon,
  ImageIcon,
  ImageIconBlue,
  CloudIcon,
}

export {
  HowToPayMan,
}
