import { api, axiosConfig } from '../config'
import { useQuery } from '@tanstack/react-query'
import { ErrorWithResponse } from '../types'
import { IOperationData } from '../types'

export const getData = async () => {
  const config = axiosConfig()
  return await api.post('/operations/data', config.formdata, config.config)
    .then((res) => res.data)
}

const QUERY_KEY = ['data']

export const useData = () => {
  return useQuery<IOperationData, ErrorWithResponse>(QUERY_KEY, getData)
}
