import React from 'react'
import { useState, useEffect, useRef } from 'react'

import * as amplitude from '@amplitude/analytics-browser'
import moment from 'moment/moment'
import 'moment-timezone'
import { IOperationData } from '../../api/types'
import { useCheck } from '../../api/operations/useCheck'
import Text from '../Text'
import { handleLocation } from '../../utils/handleLocation'
import Button from '../Button'
import OpenTidio from '../Tidio/openTidio'

const timeRedirect = 0.3

interface ITimer {
  data: IOperationData
}

const Timer = ({ data }: ITimer) => {
  const { mutate: checkMutate } = useCheck()

  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const [time, setTime] = useState< number | false >(false)
  const latestTime = useRef<number | false>(false)
  // const [supportEnabled, setSupportEnabled] = useState<boolean>(true)

  const runCheck = () => {
    if (latestTime.current !== false && latestTime.current <= 0 && (data.status == 'NEW' || data.status == 'WAITING_PAYMENT')) {
      checkMutate()
    }
  }

  const getTime = (deadline: number) => {
    const d = moment.tz(Date.now(), 'Asia/Brunei')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const time = deadline * 1000 - d

    setTime(time)
    setMinutes(Math.floor(time / 1000 / 60))
    setSeconds(Math.floor((time / 1000) % 60))
  }
  const getTimeRedirect = (deadline: number) => {
    const time = deadline - Date.now()
    if (time <= 0) {
      if (data.url) handleLocation(data.url, 'Redirect')
    }

    setTime(time)
    setMinutes(Math.floor(time / 1000 / 60))
    setSeconds(Math.floor((time / 1000) % 60))
  }

  useEffect(() => {
    if (
      data.url &&
      (data.status === 'SUCCESS' ||
        data.status === 'FAILED')
    ) {
      const deadline = Date.now() + timeRedirect * 60 * 1000
      const interval = setInterval(() => getTimeRedirect(deadline), 1000)
      return () => clearInterval(interval)
    } else {
      if (data.status === 'HOLD') {
        const deadline = Date.now() + 1000
        const interval = setInterval(() => getTimeRedirect(deadline), 1000)
        return () => clearInterval(interval)
      } else {
        const payment_check_timeout_date_timestamp = data.payment_check_timeout_date_timestamp
        const deadline = (payment_check_timeout_date_timestamp !== null && payment_check_timeout_date_timestamp !== undefined)
          ? payment_check_timeout_date_timestamp
          : data.payment_timeout_date_timestamp
        const interval = setInterval(() => getTime(new Date(deadline!).getTime()), 1000)
        return () => clearInterval(interval)
      }
    }
  }, [data])

  // Update the time variable
  useEffect(() => {
    latestTime.current = time
  }, [time])

  // Trigger the check mutation
  useEffect(() => {
    const checkInterval = setInterval(() => runCheck(), 3000)
    return () => clearInterval(checkInterval)
  }, [data])

  // useEffect(() => {
  //   OpenTidio()
  // }, [])

  return (
    <div className={'flex justify-center self-center'}>
      {time === false ? <div className='flex gap-1 h-[22.5px] '></div> : time > 0 ?
        <div className={'flex gap-1 whitespace-nowrap'}>
          {data.status === 'WAITING_PAYMENT' ? <Text className={'whitespace-nowrap text-[15px] text-opacity-50'}>
            Max Time:
          </Text>
            :
            <Text className={'font-normal text-base text-secondary text-opacity-50'}>
            Time Left:
            </Text>}
          <Text className={'text-[15px] text-black font-semibold'}>
            {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
          </Text>
        </div>
        : data.status === 'HOLD' || data.status === 'FAILED' ?
          <Button 
            className={'btn-success justify-center text-white font-400 rounded-lt chat-button'} 
            title={'Talk to Support'} 
            onClick={ () => {
              // setSupportEnabled(false)
              amplitude.track('Click Talk to Support')
              OpenTidio()
            }}
            // disabled={!supportEnabled}
          />
          :
          <div></div>
      }
    </div>
  )
}


export default Timer
