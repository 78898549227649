import React from 'react'

interface ILayout {
  children: React.ReactNode
}

const Layout = ({ children }: ILayout) => {
  return (
    <div className='min-h-screen flex'>
      <div className='w-full bg-white sm:bg-inherit flex justify-center inset-0'>
        {children}
      </div>
    </div>
  )
}

export default Layout
